import React from "react";
import founder from "../assets/founder.jpg";
import cofounder from "../assets/cofounder.jpg";
import vijay from "../assets/vijay2.jpg";
import sudesh from "../assets/sudesh.jpg";
const Testimonals = () => {
  return (
    <div>
      <section className="bg-white">
        <div className="py-4 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-black">
              The Soulful Quadrant{" "}
            </h2>
            <p className="mb-8 font-light text-black lg:mb-16 sm:text-xl">
              We craft seamless digital experiences that elevate your business
              and leave competitors behind. Let’s unleash your brand’s potential
              together!{" "}
            </p>
          </div>
          <div className="grid mb-8 lg:mb-12 lg:grid-cols-2">
            {/* Testimonial 1 */}
            <figure
              clasname
              className="flex flex-col justify-center items-center p-8 text-center bg-white border-b border-gray-200 md:p-12 lg:border-r"
            >
              <blockquote className="mx-auto mb-8 max-w-2xl text-black">
                <h3 className="text-lg font-semibold pt-5 text-black">
                  The future belongs to those who can teach machines to speak
                  the language of humans{" "}
                </h3>
                <p className="my-4 text-lg">
                  Responsible for building sophisticated Large Language Models
                  (LLMs) that enhance AI-driven applications.
                </p>
                <p className="my-4 text-lg">
                  With expertise in natural language processing and deep
                  learning techniques, they work on crafting systems capable of
                  understanding and generating human-like text.
                </p>
              </blockquote>
              <figcaption className="flex justify-center items-center space-x-3">
                <img
                  className="w-20 h-20 rounded-full"
                  src={founder}
                  alt="founder of soulfulai"
                />
                <div className="space-y-0.5 font-medium text-black text-left">
                  <div className=" text-2xl xl:text-4xl">Afrin Fathima</div>
                  <div className="text-xl font-light text-gray-500 ">
                    LLM Developer{" "}
                  </div>
                </div>
              </figcaption>
            </figure>

            {/* Testimonial 2 */}
            <figure className="flex  mt-5 flex-col justify-center items-center p-8 text-center bg-white border-b border-gray-200 md:p-12">
              <blockquote className="mx-auto mb-8 max-w-2xl text-black">
                <h3 className="text-lg font-semibold  text-black">
                  "In the world of data, learning never stops—models evolve, and
                  so do we{" "}
                </h3>
                <p className="my-4 text-lg">
                  Dedicated to designing, training, and deploying advanced
                  Machine Learning (ML) models.
                </p>
                <p className="my-4 text-lg">
                  From data preprocessing to fine-tuning algorithms, their role
                  ensures our solutions are driven by intelligent automation and
                  insightful data analysis.
                </p>
              </blockquote>
              <figcaption className="flex  justify-center items-center space-x-3">
                <img
                  className="w-20 h-20  rounded-full"
                  src={cofounder}
                  alt="founder of soulfulai"
                />
                <div className="space-y-0.5 font-medium text-black text-left">
                  <div className=" text-2xl xl:text-4xl">Raja Murugan</div>
                  <div className="text-xl font-light text-gray-500 ">
                    Machine Learning Developer
                  </div>
                </div>
              </figcaption>
            </figure>

            {/* Testimonial 3 */}
            <figure className="flex flex-col justify-center items-center p-8 text-center bg-white border-b border-gray-200 lg:border-b-0 md:p-12 lg:border-r">
              <blockquote className="mx-auto mb-8 max-w-2xl text-black">
                <h3 className="text-lg font-semibold text-black">
                  A great product isn't built with code alone—it's crafted with
                  passion, precision, and purpose.{" "}
                </h3>
                <p className="my-4 text-lg">
                  Specializes in building high-performance software systems and
                  applications. With a focus on software architecture,
                  development, and testing, they ensure that every product we
                  create is both reliable and scalable.
                </p>
                <p className="my-4 text-lg">
                  Their coding expertise and problem-solving skills help in
                  developing seamless user experiences and robust backend
                  systems.
                </p>
              </blockquote>
              <figcaption className="flex justify-center items-center space-x-3">
                <img
                  className="w-20 h-20 rounded-full"
                  src={vijay}
                  alt="founder of soulfulai"
                />
                <div className="space-y-0.5 font-medium text-black text-left">
                  <div className="text-2xl xl:text-4xl">Vijay Guhan</div>
                  <div className="text-xl font-light text-gray-500 ">
                    Software Developer
                  </div>
                </div>
              </figcaption>
            </figure>

            {/* Testimonial 4 */}
            <figure className="flex flex-col justify-center items-center p-8 text-center bg-white border-gray-200 md:p-12">
              <blockquote className="mx-auto mb-8 max-w-2xl text-black">
                <h3 className="text-lg font-semibold text-black">
                  Innovation is writing the future, one line of code at a time{" "}
                </h3>
                <p className="my-4 text-lg">
                  A versatile programmer with experience across various
                  technologies, contributing to all phases of the development
                  cycle, from brainstorming and prototyping to coding and
                  deployment.
                </p>
                <p className="my-4 text-lg">
                  Their adaptability and collaborative spirit ensure that our
                  team remains agile, responding to challenges with creativity
                  and technical precision.
                </p>
              </blockquote>
              <figcaption className="flex justify-center items-center space-x-3">
                <img
                  className="w-20 h-20 mt-7 rounded-full"
                  src={sudesh}
                  alt="founder of soulfulai"
                />
                <div className="space-y-0.5 mt-7 font-medium text-black text-left">
                  <div className="text-2xl xl:text-4xl">Sudhesh Rajan</div>
                  <div className="text-xl font-light text-gray-500 ">
                    Developer
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
          <div className="text-center"></div>
        </div>
      </section>
    </div>
  );
};

export default Testimonals;
