import React from "react";
import Marquee from "react-fast-marquee";

const SecondPage = () => {
  return (
    <div className="w-[100%]">
      <div className="w-full pt-16 pb-16 h-full xl:pt-32 xl:pb-32">
        <div className="xl:w-[40%] xl:h-12 w-72 xl:ml-[8%] ml-2 mb-4  xl:pb-10 flex items-center justify-center">
          <span className="text-transparent bg-clip-text bg-gradient-to-l from-[#254e1f] to-[#329e6a]  font-bold font-firasano tracking-wide xl:text-4xl">
            Empowering Intelligent Solutions
          </span>
        </div>

        <p className="xl:pl-32 pl-5 text-sm xl:text-3xl w-[100%]">
          <span className="font-bold leading-relaxed tracking-wide">
            We specialize in developing and delivering custom AI and machine
            learning (ML) algorithms tailored to the specific needs of our
            clients.
          </span>
          <span className="text-[#00000075] font-bold leading-relaxed tracking-wide">
            Data-driven insights that demonstrate how our AI and ML solutions
            drive optimization and operational improvement.
          </span>
        </p>
      </div>

      <p className="text-3xl xl:text-9xl   pb-[5%] whitespace-nowrap overflow-x-auto justify-center pl-[20%]">
        OUR SOLUTIONS
      </p>
    </div>
  );
};

export default SecondPage;
