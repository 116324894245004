import React, { useState } from "react";
const Header = ({
  scrollToHome,
  scrollToSecondPage,
  scrollToProjects,
  scrollToWork,
  scrollToFooter,
  scrollToTestimonials,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
    console.log(isMenuOpen);
  };

  return (
    <div className="absolute z-20 w-full">
      <nav className="bg-transparent">
        <div className="flex flex-wrap items-center justify-between mx-auto p-4">
          <h1 className="xl:text-4xl ml-6 font-Playwrite text-white">
            SoulfulAI
          </h1>
          <button
            onClick={handleMenuToggle}
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded={isMenuOpen ? "true" : "false"}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div
            className={`${
              isMenuOpen ? "" : "hidden"
            } w-full md:block md:w-auto`}
            id="navbar-default"
          >
            <ul className="font-medium bg-black xl:bg-transparent mr-10 flex flex-col p-4 md:p-0 mt-4 border rounded-lg w-full  md:flex-row md:space-x-8 md:mt-0 md:border-0">
              <li>
                <a
                  onClick={() => {
                    handleMenuToggle();
                    scrollToHome();
                  }}
                  href="#"
                  className="block py-2 px-3 xl:text-2xl text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    handleMenuToggle();
                    scrollToSecondPage();
                  }}
                  href="#"
                  className="block py-2 xl:text-2xl px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    handleMenuToggle();
                    scrollToProjects();
                  }}
                  href="#"
                  className="block py-2 px-3 xl:text-2xl text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  Services
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    // handleMenuToggle();
                    // scrollToWork();
                  }}
                  href="#"
                  className="block py-2 px-3 xl:text-2xl text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  Projects
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    handleMenuToggle();
                    scrollToFooter();
                  }}
                  href="#"
                  className="block py-2 px-3 xl:text-2xl text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
