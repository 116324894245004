import React from "react";

const Projects = () => {
  return (
    <div className="flex flex-col xl:grid xl:grid-cols-3  items-center  gap-5 mb-10">
      <div
        className="text-white border-1 rounded-xl xl:w-[95%] h-96 bg-cover mx-5"
        style={{
          backgroundImage: `url("https://img.freepik.com/free-vector/line-luxury-gradient-color-minimalist-style-wave_483537-3954.jpg?t=st=1727686278~exp=1727689878~hmac=764e4edb97aa8cc709896036db0699de4a3414f6783550a0ea2f0b768514fe6e&w=1060")`,
        }}
      >
        <div className="p-3 pl-4 xl:p-3 xl:pt-5 xl:pl-5">
          <h1 className="xl:text-4xl text-2xl font-bold text-start">
            Our Key Offerings
          </h1>
        </div>
        <p className="xl:text-xl text-justify xl:p-5 p-3 pl-4 xl:pt-8 leading-relaxed xl:leading-relaxed tracking-wide">
          We specialize in AI and ML algorithm development tailored to meet the
          specific requirements of our clients. Our solutions are designed to
          deliver data-driven insights that effectively demonstrate how our AI
          and ML applications drive optimization and operational improvement.
        </p>
      </div>

      <div
        className="text-white border-1 rounded-xl xl:w-[95%] h-96 bg-cover mx-5"
        style={{
          backgroundImage: `url("https://img.freepik.com/free-vector/line-luxury-gradient-color-minimalist-style-wave_483537-3954.jpg?t=st=1727686278~exp=1727689878~hmac=764e4edb97aa8cc709896036db0699de4a3414f6783550a0ea2f0b768514fe6e&w=1060")`,
        }}
      >
        <div className="p-3 pl-4 xl:p-3 xl:pt-5 xl:pl-5">
          <h1 className="xl:text-4xl text-2xl font-bold text-start">
           Our Uniqueness 
          </h1>
        </div>
        <p className="xl:text-xl text-justify xl:p-5 p-3 pl-4 xl:pt-8 leading-relaxed xl:leading-relaxed tracking-wide">
          At SOULFUL AI, we're dedicated to harnessing the power of artificial
          intelligence and machine learning to solve simple problems to complex
          problems and necessary needs. Our mission is to make AI solutions
          accessible and understandable to every sector.
        </p>
      </div>

      <div
        className="text-white border-1 rounded-xl xl:w-[95%] h-96 bg-cover mx-5"
        style={{
          backgroundImage: `url("https://png.pngtree.com/thumb_back/fh260/background/20230415/pngtree-website-technology-line-dark-background-image_2344719.jpg")`,
        }}
      >
        <div className="p-3 pl-4 xl:pt-5 xl:p-3 xl:pl-5">
          <h1 className="xl:text-4xl text-2xl font-bold text-start">AI-OS </h1>
        </div>
        <p className="xl:text-xl text-justify xl:p-5 p-3 pl-4 xl:pt-8 leading-relaxed xl:leading-relaxed tracking-wide">
          Customized AI Solutions: We specialize in delivering tailored AI
          solutions designed around each client’s unique requirements. Our
          offerings include seamless AI integration into websites, advanced
          smart assistants, and AI-powered models for rapid data analysis and
          insights
        </p>
      </div>
    </div>
  );
};

export default Projects;
