import React from "react";
import background from "../assets/bg-soulful.mp4";

const HomePage = () => {
  return (
    <div className="relative w-full xl:h-screen h-96 text-2xl p-1 xl:pl-3 xl:text-3xl">
      <video
        autoPlay
        loop
        muted
        playsInline
        className="absolute top-0 left-0 w-full h-full object-cover"
      >
        <source src={background} type="video/mp4" />
      </video>
      {/* <Header /> */}
      <p className="relative z-10 flex justify-center xl:text-8xl pt-24 text-2xl text-center xl:pt-[15%] font-Playwrite leading-relaxed text-white">
        Crafting smart, scalable models for your business success
      </p>

      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
    </div>
  );
};

export default HomePage;
