import React, { useRef } from "react";
import { motion } from "framer-motion"; // Import motion from Framer Motion
import HomePage from "../components/HomePage";
import SecondPage from "../components/SecondPage";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Projects from "../components/Projects";
import Work from "../components/Work";
import Testimonals from "../components/Testimonals";
import AnimatedCursor from "react-animated-cursor";
const FrontPage = () => {
  const homeRef = useRef(null);
  const secondPageRef = useRef(null);
  const projectsRef = useRef(null);
  const workRef = useRef(null);
  const footerRef = useRef(null);
  const testimonialsRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  // Define motion variants for animations
  const sectionVariants = {
    hidden: { opacity: 0, y: 0 }, // Initial state
    visible: { opacity: 1, y: 20 }, // Final state
  };

  return (
    <div>
      <Header
        scrollToHome={() => scrollToSection(homeRef)}
        scrollToSecondPage={() => scrollToSection(secondPageRef)}
        scrollToProjects={() => scrollToSection(projectsRef)}
        scrollToWork={() => scrollToSection(workRef)}
        scrollToFooter={() => scrollToSection(footerRef)}
        scrollToTestimonials={() => scrollToSection(testimonialsRef)}
      />
      <div>
        <HomePage />
      </div>
      <motion.div
        ref={secondPageRef}
        initial="hidden"
        whileInView="visible"
        variants={sectionVariants}
        transition={{ duration: 1.3 }}
        viewport={{ once: false }}
      >
        <SecondPage />
      </motion.div>
      <motion.div 
        ref={projectsRef}
        initial="hidden"
        whileInView="visible"
        variants={sectionVariants}
        transition={{ duration: 1.3 }}
        viewport={{ once: false }}
      >
        <Projects />
      </motion.div>
      <motion.div
        ref={workRef}
        initial="hidden"
        whileInView="visible"
        variants={sectionVariants}
        transition={{ duration: 1.3 }}
        viewport={{ once: false }}
      >
        <Work />
      </motion.div>
      <motion.div
        ref={testimonialsRef}
        initial="hidden"
        whileInView="visible"
        variants={sectionVariants}
        transition={{ duration: 1.3 }}
        viewport={{ once: false }}
      >
        <Testimonals />
      </motion.div>
      <motion.div
        ref={footerRef}
        initial="hidden"
        whileInView="visible"
        variants={sectionVariants}
        transition={{ duration: 1.3 }}
        viewport={{ once: false }}
      >
        <Footer />
      </motion.div>
    </div>
  );
};

export default FrontPage;
