import React, { useState, useEffect } from "react";
import FrontPage from "./components/FrontPage";
import AnimatedCursor from "react-animated-cursor";

const App = () => {
  const [isXlScreen, setIsXlScreen] = useState(false);

  const updateScreenSize = () => {
    if (window.innerWidth >= 1280) {
      setIsXlScreen(true);
    } else {
      setIsXlScreen(false);
    }
  };

  useEffect(() => {
    updateScreenSize();
    return () => {
      updateScreenSize();
    };
  }, []);

  return (
    <div>
      <FrontPage />
      {isXlScreen && (
        <AnimatedCursor
          innerSize={0}
          outerSize={50}
          color="18, 41, 18"
          outerAlpha={0.4}
          innerScale={0}
          outerScale={5}
          hasBlendMode={true}
        />
      )}
    </div>
  );
};

export default App;
